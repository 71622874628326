<nav class="navbar sticky-top navbar-light bg-light header">
    <div class="container-fluid">
        <div class="row justify-content-between">
            <div class="col col-4 col-lg-5 col-md-4 align-self-center d-inline-flex">
                <a class="navbar-brand">
                    <img (click)="openNewTab()" src="assets/icons/logo.png" alt="c¡nch" class="navbar-brand logo" />
                </a>
            </div>
            <div class="col col-6 align-self-center right" *ngIf="!logged">
                <div class="row">
                    <div class="col col-10 align-self-center justify-content-end">
                        <div class="introducing-pay-now-container">
                            <div class="label">
                                Introducing the Qwikkit Payment Portal
                            </div>
                            <div class="arrow">
                                <div class="rectangle">
                                    <span>NEW</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-2">
                        <button class="buttons" (click)="payNow()">Pay Now</button>
                    </div>
                </div>
            </div>
            <div class="col col-4 col-md-2 d-flex justify-content-center align-self-center no-wrap" *ngIf="logged">
                <button class="buttons no-wrap" id="btnWatchOrderingTutorial" (click)="onPlayWatchVideo()">Watch Ordering Tutorial</button>
            </div>
            <div class="col col-3 col-md-4" style="right: -18px;" *ngIf="logged">
                <span class="circle link" [matMenuTriggerFor]="menu">
                    <mat-icon class="icon-color-user">menu</mat-icon>
                    <mat-menu #menu="matMenu" yPosition="below" class="custom-menu" [overlapTrigger]="false">
                        <button class="item-custom left" (click)="$event.stopPropagation()" mat-menu-item>Customer No. {{landingApartmentComplexService.dashBoardText.costumer_number}}</button>
                        <button class="item-custom left" mat-menu-item (click)="goDashboard()">{{myDashboard}}</button>
                        <button class="item-custom left" mat-menu-item (click)="payNow()">Payment Portal</button>
                        <button class="item-custom left" mat-menu-item id="btnUpdateAccountInformation" (click)="onEditAccount()">Update Account Information</button>
                        <button class="item-custom left" mat-menu-item (click)="router.navigate(['shopping-cart/true'])">Quotes</button>
                        <hr>
                        <button class="item-custom left" mat-menu-item (click)="logOut()">{{signOut}}</button>
                    </mat-menu>
                </span>
                <img (click)="showNotify()" *ngIf="notifyList.length === 0" class="link notification" id="btnShoppingCart" src="assets/icons/notifications.svg" />
                <img (click)="showNotify()" *ngIf="notifyList.length > 0" class="link notification" id="btnShoppingCart" src="assets/icons/notifications-unread.svg" />
                <img [routerLink]="['shopping-cart/false']" *ngIf="!hasLedgeEntries && !isOnShoppingCart" class="cart link" id="btnShoppingCart" src="assets/icons/shopping-cart.svg" />
            </div>
        </div>
    </div>
</nav>
<div id="notification-modal" bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true" >
    <div class="modal-frame modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <div class="header-notify d-flex justify-content-center">My Notifications</div>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="notifyList.length > 0; else noNotifications">
                    <div *ngFor="let notify of notifyList">
                        <div class="row" style="display: flex; align-items: center;">
                            <div class="col-sm-2" style="top:10px">
                                <mat-icon>mode_comment</mat-icon>
                            </div>
                            <div class="col-sm-8" style="top:10px">
                                <p class="notify-text">
                                    {{ notify.notify }} <br />
                                    <strong>{{ notify.createdDate | date }}</strong>
                                </p>
                            </div>
                                <div id="dismiss-button" class="d-flex justify-content-end">
                                    <a class="link" (click)="markReadNotification(notify)">Dismiss</a>
                                </div>    
                        </div>
                    <hr/>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button (click)="markReadAllNotification()" class="buttons btn-noty">
                            <span>Dismiss All</span>
                        </button>
                    </div>
                </ng-container>
                <ng-template #noNotifications>
                    <span>No new notifications.</span>
                </ng-template>
        </div>
    </div>
</div>
</div>
<app-modal #orderingVideo>
    <div class="modal-body" (contextmenu)="$event.preventDefault();">
        <iframe *ngIf="videoUrl" class="vimeo-iframe" [src]="videoUrl" frameborder="0" allow="autoplay; fullscreen"
            allowfullscreen></iframe>
    </div>
</app-modal>